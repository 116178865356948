require( 'iframe-resizer' );

( ( $ ) => {
  if ( $( 'iframe' ).length ) { 
    $( 'iframe' ).each( ( index, element ) => {
      const iframeSrc = $( element ).attr( 'src' );
      if( iframeSrc.indexOf( 'https://go.drivewyze.com' ) !=-1 ) {
        /* eslint-disable no-undef */
        iFrameResize( {
          log: false,
          checkOrigin: iframeSrc,
          heightCalculationMethod: 'bodyScroll',
        }, $( element ).get( 0 ) );
      }
    } );
  }
} )( jQuery );